import axios, { AxiosError } from 'axios'
import {
  AND_PARAM,
  CANCEL_PATH,
  CHANGE_COMPANY_PATH,
  CHECKOUT_DETAILS_PATH,
  CLEAN_CART,
  CLOSE_PATH,
  CLOSE_TUTORIAL_PATH,
  COMPANIES_PATH,
  COMPANY_ID_PARAM,
  COMPANY_INFORMATION_PATH,
  COMPANY_NAME_PARAM,
  COMPANY_PATH,
  COMPLETE_PATH,
  COPY_PROCESS_PATH,
  DATAWEB_PATH,
  DEFAULT,
  DETAILS_BY_PRODUCTS_ID,
  DETAILS_PROMOTIONAL_CODE_ID_PATH,
  DISABLED,
  EMAIL_SERVICE_PATH,
  ENABLED,
  EXPIRED_PRODUCTS_PATH,
  INVOICE_CONTRACTS_PATH,
  INVOICE_CONTRACT_ID_PARAM,
  INVOICE_DETAILS,
  INVOICE_EMMITED,
  ISSUER_INVOICE_INFORMATION,
  LICENSES_PATH,
  MAIN_SOLUTIONS_PATH,
  MAIN_SOLUTION_ID_PARAM,
  NOTIFICATION_REFUND_REQUEST_PATH,
  PERMANENTLY_DELETE_PATH,
  PROCESSES_PATH,
  PROCESS_ID_PARAM,
  PROCESS_PATH,
  PROCESS_PRODUCT_PATH,
  PRODUCTS_PATH,
  PRODUCTS_TO_ACTIVATE_PATH,
  PROMOTIONAL_CODE_PATH,
  REFERENCE_PARAM,
  REFUND_PATH,
  REFUND_REQUESTS_PATH,
  REMOVE_CODE,
  SEARCH_PARAM,
  SPECIAL_PRODUCTS_PATH,
  STEPS_PATH,
  TRANSACTIONS_PATH,
  TRANSACTION_LICENSE_PATH,
  UPLOAD_PATH,
  VALIDATE_CODE,
  VALIDATE_CODE_LOGGED,
  VALIDATE_RUC_PATH,
  VERIFY_IF_EXISTS_EMAIL_PATH,
  VERIFY_IF_EXISTS_PATH,
} from 'lib'

import {
  IBankingInformation,
  ICartProduct,
  ICompanyInformation,
  IInvoiceContract,
  IInvoiceDetails,
  IProduct,
  ISolutionProduct,
  ISpecialProduct,
  ITransaction,
  IValidateCode,
} from 'marketplace-common'
import {
  INotificationRefundRequest,
  IStep,
  IValidateProduct,
  InvoiceContractWithProcessRequest,
} from 'types'
import {
  requestInterceptorErrorSecurityAdministration,
  requestInterceptorSuccessAdministration,
} from './administration.interceptor'

const getItem = (key: string): any | undefined => {
  const value = localStorage.getItem(key)
  try {
    if (value) {
      const json = JSON.parse(value)
      return json
    }
  } catch (error) {
    return undefined
  }
}

type IHeaders = {
  Authorization: string
  Token: string
}
const getHeaders = (): IHeaders => {
  const token = getItem('alterbios_token_key') ?? ''
  return {
    Authorization: `Bearer ${process.env.REACT_APP_ADMINISTRATION_API_KEY}`,
    Token: token,
  }
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_ADMINISTRATION_BASE_API_URL,
})
instance.interceptors.request.use(
  requestInterceptorSuccessAdministration,
  requestInterceptorErrorSecurityAdministration
)
/**Solution services */

export const getMainSolutionsService = async () => {
  try {
    const result = await instance.get(MAIN_SOLUTIONS_PATH)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getMainSolutionsByUserIdentificationService = async (userIdentification: string) => {
  try {
    const result = await instance.get(
      `${MAIN_SOLUTIONS_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${userIdentification}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getMainSolutionsByReferenceService = async (reference: string) => {
  try {
    const result = await instance.get(
      `${MAIN_SOLUTIONS_PATH}${SEARCH_PARAM}${REFERENCE_PARAM}${reference}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Steps services */

export const getProcessStepsService = async () => {
  try {
    const result = await instance.get(`${STEPS_PATH}`)
    return result.data as IStep[]
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Process services */

export const getProcessByIdService = async (id: number) => {
  try {
    const result = await instance.get(`${PROCESSES_PATH}/${id}`)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getProcessesByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${PROCESSES_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const createProcessesService = async (process: any) => {
  try {
    const result = await instance.post(`${PROCESSES_PATH}`, process)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const updateProcessesService = async (process: any, id: number) => {
  try {
    const result = await instance.put(`${PROCESSES_PATH}/${id}`, process)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const closeProcessService = async (processId: number) => {
  try {
    const result = await instance.put(`${PROCESSES_PATH}/${processId}${CLOSE_PATH}`)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const completeProcessService = async (process: any) => {
  try {
    const result = await instance.put(`${PROCESSES_PATH}${COMPLETE_PATH}`, process)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const cancelProcessService = async (processId: number) => {
  try {
    const result = await instance.put(`${PROCESSES_PATH}/${processId}${CANCEL_PATH}`)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Product services */

export const getProductsByMainSolutionIdService = async (mainSolutionId: number) => {
  try {
    const result = await instance.get(
      `${PRODUCTS_PATH}${SEARCH_PARAM}${MAIN_SOLUTION_ID_PARAM}${mainSolutionId}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getAllProductsToActivateByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${PRODUCTS_PATH}${PRODUCTS_TO_ACTIVATE_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data as ISolutionProduct[]
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getExpiredProductsByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${PRODUCTS_PATH}${EXPIRED_PRODUCTS_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data as ISolutionProduct[]
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/** Transaction services */

export const createTransactionService = async (transaction: any) => {
  try {
    const result = await instance.post(`${TRANSACTIONS_PATH}`, transaction)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const createTransactionByProcessAndInvoiceContractService = async (
  processId: number,
  invoiceContractId: number
) => {
  try {
    const result = await instance.post(
      `${TRANSACTIONS_PATH}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}${AND_PARAM}${INVOICE_CONTRACT_ID_PARAM}${invoiceContractId}`
    )
    return result.data as ITransaction
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getTransactionsByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${TRANSACTIONS_PATH}${COMPANY_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getTransactionsByProcessIdService = async (processId: number) => {
  try {
    const result = await instance.get(
      `${TRANSACTIONS_PATH}${PROCESS_PATH}${SEARCH_PARAM}${PROCESS_ID_PARAM}${processId}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const updateTransactionService = async (
  transactionId: number,
  transactionRequest: {
    processId: number
  }
) => {
  try {
    const result = await instance.put(`${TRANSACTIONS_PATH}/${transactionId}`, transactionRequest)
    return result.data as ITransaction
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/** Transaction dataweb services */

export const checkoutDatawebTransactionService = async (transaction: any) => {
  try {
    const result = await instance.post(
      `${TRANSACTIONS_PATH}${DATAWEB_PATH}${CHECKOUT_DETAILS_PATH}`,
      transaction
    )
    return result.data
  } catch (error: any) {
    console.log(JSON.stringify(error))
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** Invoice contract services */

export const createInvoiceContractService = async (invoiceContract: any) => {
  try {
    const result = await instance.post(`${INVOICE_CONTRACTS_PATH}`, invoiceContract)
    return result.data
  } catch (error: any) {
    console.log(JSON.stringify(error))
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const updateInvoiceContractService = async (
  invoiceContractId: number,
  invoiceContractRequest: {
    processId: number
  }
) => {
  try {
    const result = await instance.put(
      `${INVOICE_CONTRACTS_PATH}/${invoiceContractId}`,
      invoiceContractRequest
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getInvoiceRefundService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${INVOICE_CONTRACTS_PATH}${REFUND_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** License services */

export const createLicenseService = async (license: any) => {
  try {
    const result = await instance.post(`${LICENSES_PATH}`, license)
    return result.data
  } catch (error: any) {
    console.log(JSON.stringify(error))
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getLicensesByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${LICENSES_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error: any) {
    console.log(JSON.stringify(error))
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** Company services */

export const getCompanyByIdService = async (companyId: string) => {
  try {
    const result = await instance.get(`${COMPANIES_PATH}/${companyId}`)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const createCompanyService = async (company: any) => {
  try {
    const result = await instance.post(`${COMPANIES_PATH}`, company)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const closeTutorialService = async (companyId: string) => {
  try {
    await instance.put(`${COMPANIES_PATH + CLOSE_TUTORIAL_PATH}`, {
      companyId,
    })
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** Refund request services */

export const createRefundRequestService = async (request: any) => {
  try {
    const result = await instance.post(`${REFUND_REQUESTS_PATH}`, request)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getRefundRequestsByCompanyIdService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${REFUND_REQUESTS_PATH}${SEARCH_PARAM}${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** Process product services */

export const addProcessProductService = async (request: {
  productId?: number
  processId: number
}) => {
  try {
    const result = await instance.post(`${PROCESS_PRODUCT_PATH}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const addProcessProductSpecialProductService = async (request: {
  specialProductId?: number
  processId: number
}) => {
  try {
    const result = await instance.post(`${PROCESS_PRODUCT_PATH}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const removeProcessProductService = async (id: number) => {
  try {
    const result = await instance.delete(`${PROCESS_PRODUCT_PATH}/${id}`)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getProductsByProcessIdService = async (processId: number) => {
  try {
    const result = await instance.get(`${PROCESS_PRODUCT_PATH}/${processId}`)
    return result.data as ICartProduct[]
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getProductsByIdService = async (productId: number) => {
  try {
    const result = await instance.get(`${PRODUCTS_PATH}/${productId}`)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getProductEnableService = async () => {
  try {
    const result = await instance.get(`${PRODUCTS_PATH}`)
    return result.data as IProduct[]
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const cleanCartByProcessIdService = async (request: { processId: number }) => {
  try {
    const result = await instance.put(`${PROCESS_PRODUCT_PATH}/${CLEAN_CART}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/** Company information services */

export const getCompanyInformationService = async (companyId: string) => {
  try {
    const result = await instance.get(`${COMPANY_INFORMATION_PATH}?${COMPANY_ID_PARAM}${companyId}`)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getAllCompanyInformationEnableService = async (companyId: string) => {
  try {
    const result = await instance.get(
      `${COMPANY_INFORMATION_PATH}${ENABLED}?${COMPANY_ID_PARAM}${companyId}`
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getCompanyInformationByIdService = async (companyInformationId: number) => {
  try {
    const result = await instance.get(`${COMPANY_INFORMATION_PATH}/${companyInformationId}`)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const createCompanyInformationDefaultService = async (request: ICompanyInformation) => {
  try {
    const result = await instance.post(`${COMPANY_INFORMATION_PATH}${DEFAULT}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const createCompanyInformationEnabledService = async (request: ICompanyInformation) => {
  try {
    const result = await instance.post(`${COMPANY_INFORMATION_PATH}${ENABLED}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const createCompanyInformationDisabledService = async (request: ICompanyInformation) => {
  try {
    const result = await instance.post(`${COMPANY_INFORMATION_PATH}${DISABLED}`, request)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const deleteCompanyInformationService = async (companyInformationId: number) => {
  try {
    const result = await instance.put(
      `${COMPANY_INFORMATION_PATH}${DISABLED}/${companyInformationId}`
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const updateCompanyInformationService = async (
  companyInformationId: number,
  request: ICompanyInformation
) => {
  try {
    const result = await instance.put(
      `${COMPANY_INFORMATION_PATH}/${companyInformationId}`,
      request
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const setDefaultCompanyInformationService = async (companyInformationId: number) => {
  try {
    const result = await instance.put(
      `${COMPANY_INFORMATION_PATH}${DEFAULT}/${companyInformationId}`
    )
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/**Invoice Details */
export const getInvoiceDetailsService = async (processId: number) => {
  try {
    const result = await instance.get(`${INVOICE_DETAILS}?${PROCESS_ID_PARAM}${processId}`)
    return result.data
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/**Invoice Details By ProductsId */
export const getInvoiceDetailsByProductsIdService = async (
  productsId?: number[],
  specialProductsId?: number[]
) => {
  try {
    const result = await instance.get(`${INVOICE_DETAILS}${DETAILS_BY_PRODUCTS_ID}`, {
      params: {
        productsId: Boolean(productsId) ? productsId?.join(',') : undefined,
        specialProductsId: Boolean(specialProductsId) ? specialProductsId?.join(',') : undefined,
      },
    })
    return result.data as IInvoiceDetails
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getCompanyByCompanyIdOrCompanyName = async (filter: string) => {
  try {
    const result = await instance.get(`${COMPANIES_PATH}`, {
      params: {
        filter,
      },
    })
    console.log(result.data, 'response')
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

/**EntityWithSearchParams */
export const listEntityWithSearchParamsService = async (
  path: string,
  params: string,
  isSecurity?: boolean
) => {
  try {
    const baseUrl = isSecurity
      ? process.env.REACT_APP_SECURITY_BASE_API_URL!
      : process.env.REACT_APP_ADMINISTRATION_BASE_API_URL!

    const headers = {
      Authorization: `Bearer ${
        isSecurity
          ? process.env.REACT_APP_SECURITY_API_KEY
          : process.env.REACT_APP_ADMINISTRATION_API_KEY
      }`,
      Token: getItem('alterbios_token_key') ?? '',
    }
    const result = await instance.get(path + params, { baseURL: baseUrl, headers })

    return result.data
  } catch (error: any) {
    console.log(JSON.stringify(error))
    throw new Error((error as AxiosError)?.response?.data as string | undefined)
  }
}

/**SpecialProducts services */

export const getSpecialProductsService = async () => {
  try {
    const result = await instance.get(SPECIAL_PRODUCTS_PATH)
    return result.data as ISpecialProduct[]
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Banking Information services */

export const getBankingInformationService = async () => {
  try {
    const result = await instance.get(ISSUER_INVOICE_INFORMATION)
    return result.data as IBankingInformation[]
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Upload Receipt service */

export const uploadReceiptService = async (formData: FormData) => {
  try {
    const result = await instance.post(TRANSACTIONS_PATH + UPLOAD_PATH, formData)
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**validateCode logged service */
export const validateCodeLoggedService = async (
  code: string,
  processId: number,
  productsId?: number[],
  specialProductsId?: number[]
) => {
  try {
    const result = await instance.get(`${PROMOTIONAL_CODE_PATH + VALIDATE_CODE_LOGGED}`, {
      params: {
        code,
        processId,
        productsId: productsId && productsId.join(','),
        specialProductsId: specialProductsId && specialProductsId.join(','),
      },
    })
    return result.data as IValidateCode
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**validateCode No logged service */
export const validateCodeNotLoggedService = async (
  code: string,
  productsId?: number[],
  specialProductsId?: number[]
) => {
  try {
    const result = await instance.get(`${PROMOTIONAL_CODE_PATH + VALIDATE_CODE}`, {
      params: {
        code,
        productsId: productsId && productsId.join(','),
        specialProductsId: specialProductsId && specialProductsId.join(','),
      },
    })
    return result.data as IValidateCode
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**update Invoice with Promotional Code service */
export const updateInvoiceApplyCodeService = async (
  invoiceContractId: number,
  request: InvoiceContractWithProcessRequest
) => {
  try {
    const result = await instance.put(
      `${INVOICE_CONTRACTS_PATH + PROMOTIONAL_CODE_PATH}/${invoiceContractId}`,
      request
    )
    return result.data as IInvoiceContract
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**Get InvoiceDetails with PromotionalCode Service */
export const getInvoiceDetailsWithPromotionalCodeService = async (
  promotionalCodeId: number,
  productsId?: number[],
  specialProductsId?: number[]
) => {
  try {
    const params = new URLSearchParams({
      promotionalCodeId: promotionalCodeId.toString(),
      ...(productsId && { productsId: productsId.join(',') }),
      ...(specialProductsId && { specialProductsId: specialProductsId.join(',') }),
    })

    const result = await instance.get(
      `${INVOICE_DETAILS + DETAILS_PROMOTIONAL_CODE_ID_PATH}?${params.toString()}`
    )
    return result.data as IInvoiceDetails
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

/**remove code Service */
export const removeCodeService = async (processId: number) => {
  try {
    const result = await instance.put(PROMOTIONAL_CODE_PATH + REMOVE_CODE, { processId })
    return result.data as IValidateCode
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const verifyIfCompanyExists = async (companyId: string) => {
  try {
    await instance.get(
      COMPANIES_PATH + VERIFY_IF_EXISTS_PATH + SEARCH_PARAM + COMPANY_ID_PARAM + companyId
    )
  } catch (error) {
    console.log(error)
    throw new Error((error as AxiosError)?.response?.data)
  }
}
export const verifyIfCompanyNameExists = async (companyName: string) => {
  try {
    await instance.get(
      COMPANIES_PATH + VERIFY_IF_EXISTS_EMAIL_PATH + SEARCH_PARAM + COMPANY_NAME_PARAM + companyName
    )
  } catch (error) {
    console.log(error)
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const verifyIfUserHasRefundRequestService = async (companyId: string) => {
  try {
    const response = (
      await instance.get(REFUND_REQUESTS_PATH + VERIFY_IF_EXISTS_PATH, {
        params: { companyId: companyId },
      })
    ).data
    return response
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const emmitInvoiceService = async (invoiceContractWithProcessRequest: any) => {
  try {
    const result = await instance.post(
      `${INVOICE_CONTRACTS_PATH}${INVOICE_EMMITED}`,
      invoiceContractWithProcessRequest
    )
    return result.data
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getInvoiceContractByIdService = async (id: number) => {
  try {
    const result = await instance.get(`${INVOICE_CONTRACTS_PATH}/${id}`)
    return result.data as IInvoiceContract
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const changeCompanyIdToRucService = async (
  companyId: string,
  ruc: string,
  companyName: string
) => {
  try {
    await instance.put(COMPANIES_PATH + CHANGE_COMPANY_PATH, {
      companyId,
      ruc,
      companyName,
    })

    await deleteCompanyPermanentlyIdService(companyId)
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const getProductsValidateRucService = async (processId: number, userIdType: string) => {
  try {
    const result = (
      await instance.get(PRODUCTS_PATH + VALIDATE_RUC_PATH, { params: { processId, userIdType } })
    ).data as IValidateProduct[]
    return result
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const deleteCompanyPermanentlyIdService = async (companyId: string) => {
  try {
    await instance.delete(COMPANIES_PATH + PERMANENTLY_DELETE_PATH + `/${companyId}`)
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data.message)
  }
}

export const copyProcessService = async (processId: number, companyId: string) => {
  try {
    await instance.post(
      `${PROCESSES_PATH + COPY_PROCESS_PATH}${SEARCH_PARAM + PROCESS_ID_PARAM + processId}${
        AND_PARAM + COMPANY_ID_PARAM + companyId
      }`
    )
  } catch (error) {
    throw new Error((error as AxiosError).response?.data.message)
  }
}

export const notificationRequestRefundService = async (
  notificationRequestRefundService: INotificationRefundRequest
) => {
  try {
    const result = await instance.post(
      `${EMAIL_SERVICE_PATH}${NOTIFICATION_REFUND_REQUEST_PATH}`,
      notificationRequestRefundService
    )
    return result.status
  } catch (error: any) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}

export const getTransactionLicenseService = async (companyId: string, licenseIds: string) => {
  try {
    const result = (
      await instance.get(TRANSACTION_LICENSE_PATH, { params: { companyId, licenseIds } })
    ).data
    return result
  } catch (error) {
    throw new Error((error as AxiosError)?.response?.data)
  }
}
