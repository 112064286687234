import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Icon,
  Skeleton,
  Typography,
} from '@mui/material'

import { ProductsDetails } from 'components'

import {
  useAuth,
  useCart,
  useFeedback,
  useInvoiceContract,
  useLocalStorage,
  useNavigate,
} from 'hooks'
import { ROUTES, cancelProcessService, constLocalStorage, getProductsByProcessIdService } from 'lib'
import { IPromocionalCode, IUser } from 'marketplace-common'
import React from 'react'

type Props = {
  openDialog: boolean
  loadingInitialize: boolean
  processId: number
  handleCloseDialog: () => void
  initializeConfigureAfterLogin: (user: IUser) => Promise<void>
}

export const DialogProcess = (props: Props) => {
  const {
    openDialog,
    processId,
    loadingInitialize,
    handleCloseDialog,
    initializeConfigureAfterLogin,
  } = props
  const { refreshCart, productsIdsFromLocalStorage, specialProductsIdFromLocalStorage, process } =
    useCart()
  const { invoiceDetails, handleInvoiceDetails, loadingInvoice } = useInvoiceContract()
  const { invoiceDetails: invoiceDetailsLocal, handleInvoiceDetails: handleInvoiceDetailsLocal } =
    useInvoiceContract()
  const { deleteItem, getItem } = useLocalStorage()
  const { user, isAuthenticated } = useAuth()
  const [loadingDetails, setLoadingDetails] = React.useState<boolean>(false)
  const [loadingDetailsLocal, setLoadingDetailsLocal] = React.useState<boolean>(false)
  const { animatedNavigation } = useNavigate()
  const { showMessage } = useFeedback()

  const handleContinueOldProcess = async () => {
    if (!Boolean(processId)) {
      return
    }
    deleteItem(constLocalStorage.CART_PRODUCTS)
    deleteItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
    deleteItem(constLocalStorage.COMPANY_INFO)
    deleteItem(constLocalStorage.PROMOTIONAL_CODE)
    deleteItem(constLocalStorage.PROCESS_CREATE)
    await refreshCart(processId)
    handleCloseDialog()
    const encryptRoute = `${ROUTES.processes}/nuna`
    animatedNavigation(encryptRoute)
  }

  const getDetailsProductsByProcessId = async () => {
    try {
      setLoadingDetails(true)
      const response = await getProductsByProcessIdService(processId)
      const productsIds: number[] = response
        .filter((cart) => cart.product !== null)
        .map((cart) => cart.product!.productId)

      const specialProductsIds: number[] = response
        .filter((cart) => cart.specialProduct !== null)
        .map((cart) => cart.specialProduct!.specialProductId)
      await handleInvoiceDetails(
        productsIds,
        specialProductsIds,
        process?.promotionalCode?.promotionalCodeId
      )
    } catch (error) {
      console.log('Error', error)
    } finally {
      setLoadingDetails(false)
    }
  }

  const promotionalCode = getItem(constLocalStorage.PROMOTIONAL_CODE) as IPromocionalCode

  const getDetailsProductsFromLocalStorage = async () => {
    try {
      setLoadingDetailsLocal(true)
      const existPromotionalCodeId =
        promotionalCode !== undefined ? promotionalCode.promotionalCodeId : undefined
      await handleInvoiceDetailsLocal(
        productsIdsFromLocalStorage,
        specialProductsIdFromLocalStorage,
        existPromotionalCodeId
      )
    } catch (error) {
      console.log('Error', error)
    } finally {
      setLoadingDetailsLocal(false)
    }
  }

  const dateProcessCreateLocal = getItem(constLocalStorage.PROCESS_CREATE)

  React.useEffect(() => {
    const loadInformation = async () => {
      const existProductsLocalStorage = getItem(constLocalStorage.CART_PRODUCTS)
      const existSpecialProductsLocalStorage = getItem(constLocalStorage.CART_SPECIAL_PRODUCTS)
      const existProcessAndAutenticated = isAuthenticated && processId !== undefined
      if (existProcessAndAutenticated) {
        await getDetailsProductsByProcessId()
      }
      if (existProductsLocalStorage || existSpecialProductsLocalStorage) {
        await getDetailsProductsFromLocalStorage()
      }
    }
    loadInformation()
  }, [isAuthenticated, processId])

  const handleContinueNewProcess = async () => {
    try {
      if (processId !== undefined) {
        await cancelProcessService(processId)
      }
      await initializeConfigureAfterLogin(user)
      handleCloseDialog()
    } catch (error) {
      showMessage(
        'Error al cancelar el anterior carrito, revise que su carrito actual no este en el ultimo paso del proceso ',
        'error'
      )
    }
  }

  const existInvoiceDetails = React.useMemo(() => {
    return (
      invoiceDetails !== undefined &&
      invoiceDetails.products &&
      (invoiceDetails.products.length > 0 || invoiceDetails.specialProducts.length > 0)
    )
  }, [invoiceDetails])

  const existInvoiceDetailsLocal = React.useMemo(() => {
    return (
      invoiceDetailsLocal !== undefined &&
      invoiceDetailsLocal.products &&
      (invoiceDetailsLocal.products.length > 0 || invoiceDetailsLocal.specialProducts.length > 0)
    )
  }, [invoiceDetailsLocal])

  const loadingProcess = React.useMemo(() => {
    return loadingInvoice || loadingDetailsLocal || loadingDetails
  }, [loadingInvoice, loadingDetailsLocal, loadingDetails])

  const HeaderSkeleton = () => {
    return (
      <React.Fragment>
        <Skeleton
          key={'skeleton'}
          variant="rectangular"
          sx={{ width: '250px', py: 1.3, mb: 1, borderRadius: 3 }}
        />
        <Skeleton
          key={'skeleton1'}
          variant="rectangular"
          sx={{ width: '300px', py: 0.3, mb: 1, borderRadius: 3 }}
        />
      </React.Fragment>
    )
  }

  const BodySkeleton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          width: '100%',
          marginY: 3,
          gap: 2,
        }}
      >
        {Array.from({ length: 1 }).map((_, index) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 1.3,
                alignItems: 'center',
                width: '100%',
                minWidth: 415,
              }}
            >
              <Skeleton
                key={'skeleton' + index}
                variant="rectangular"
                sx={{ width: '40%', height: 20, borderRadius: 3 }}
              />
              <Skeleton
                key={'skeleton' + index}
                variant="rectangular"
                sx={{ width: '80%', height: 20, borderRadius: 3 }}
              />
              <Skeleton
                key={'skeleton' + index}
                variant="rectangular"
                sx={{ width: '80%', height: 230, borderRadius: 3 }}
              />
            </Box>
          </>
        ))}
      </Box>
    )
  }

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pb: 2 }}>
        {'Selecciona el carrito de compras'}
      </DialogTitle>

      <DialogActions
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <React.Fragment>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1.4,
            }}
          >
            {loadingProcess ? (
              <React.Fragment>
                <HeaderSkeleton />
              </React.Fragment>
            ) : (
              <>
                <Button
                  startIcon={<Icon>shopping_cart</Icon>}
                  sx={{ width: '250px', py: 1.3, mb: 1 }}
                  onClick={async () => {
                    await handleContinueOldProcess()
                  }}
                >
                  Conservar carrito anterior
                </Button>
                <Typography fontWeight={600} variant="caption" align="center">
                  {`Carrito creado : `}
                  <span style={{ fontWeight: 'bold' }}>{process?.createdDate}</span>
                </Typography>
              </>
            )}

            {process?.promotionalCode !== null && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 3,
                }}
              >
                <Icon sx={{ color: '#8ee62a' }}>local_offer_outlined</Icon>
                <Typography fontWeight={600} variant="caption" align="center">
                  {`Código Promocional Aplicado: `}
                  <span style={{ fontWeight: 'bold' }}>{process?.promotionalCode?.code}</span>
                </Typography>
              </Box>
            )}
            {loadingProcess ? (
              <BodySkeleton />
            ) : existInvoiceDetails ? (
              <Box
                sx={{
                  width: '100%',
                  paddingTop: 3,
                  paddingBottom: 4,
                }}
              >
                <ProductsDetails invoiceDetail={invoiceDetails} disableDelete />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 8,
                  paddingX: { xs: 0, sm: 5, md: 9 },
                }}
              >
                <Typography fontWeight={600} variant="caption" align="center" fontSize={14}>
                  {'No existen productos Agregados'}
                </Typography>
              </Box>
            )}
          </Box>

          <Divider orientation="vertical" flexItem>
            <Icon>filter_list</Icon>
          </Divider>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1.4,
            }}
          >
            {loadingProcess ? (
              <React.Fragment>
                <HeaderSkeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LoadingButton
                  sx={{ width: '250px', py: 1.3, mb: 1 }}
                  startIcon={<Icon>add_shopping_cart</Icon>}
                  loading={loadingInitialize}
                  disabled={loadingInitialize}
                  onClick={async () => {
                    await handleContinueNewProcess()
                  }}
                >
                  Iniciar con el nuevo carrito
                </LoadingButton>
                <Typography fontWeight={600} variant="caption" align="center">
                  {`Carrito creado : `}
                  <span style={{ fontWeight: 'bold' }}>{dateProcessCreateLocal}</span>
                </Typography>
              </React.Fragment>
            )}

            {promotionalCode !== undefined && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 3,
                }}
              >
                <Icon sx={{ color: '#8ee62a' }}>local_offer_outlined</Icon>
                <Typography fontWeight={600} variant="caption" align="center">
                  {`Código Promocional Aplicado: `}
                  <span style={{ fontWeight: 'bold' }}>{promotionalCode.code}</span>
                </Typography>
              </Box>
            )}
            {loadingProcess ? (
              <BodySkeleton />
            ) : existInvoiceDetailsLocal ? (
              <Box
                sx={{
                  width: '100%',
                  overflow: 'scroll',
                  paddingTop: 3,
                  paddingBottom: 4,
                }}
              >
                <ProductsDetails invoiceDetail={invoiceDetailsLocal} disableDelete />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 8,
                  paddingX: { xs: 0, sm: 5, md: 9 },
                }}
              >
                <Typography fontWeight={600} variant="caption" align="center" fontSize={14}>
                  {'No existen productos Agregados'}
                </Typography>
              </Box>
            )}
          </Box>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  )
}
