import { passwordStrength } from 'check-password-strength'
import { useFeedback } from 'hooks/feedback/useFeedback'
import {
  createCompanyService,
  createUserService,
  IDENTIFICATION_CARD_ID,
  OUTSIDE_ID,
  PASSPORT,
  REQUIRE_VALIDATION_TYPES,
  ROUTES,
  RUC_ID,
  validateExistingEmailService,
  validateExistingIdentificationService,
  validateIdentificationCard,
  validateOutSideId,
  validatePassport,
  validateSingleRegistryOfTaxpayers,
  verifyIfCompanyExists,
  verifyIfCompanyNameExists,
} from 'lib'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useNavigate } from 'react-router-dom'
import { IUserRequest } from 'types'
import { encryptParam } from 'utils'
import isEmail from 'validator/lib/isEmail'

type Hook = {
  errors: ValidationFields[]
  loading: ValidationFields[]
  user: IUserRequest
  reasons: UserValidationFields
  validated: Validated
  creating: boolean
  handleChange: (key: keyof IUserRequest, value: any) => void
  validateField: (key: ValidationFields) => Promise<void>
  createUser: (
    fromModal?: boolean,
    handleChangeVerification?: () => void
  ) => Promise<IUserRequest | undefined>
}

type ValidationFields = 'identification' | 'email' | 'password' | 'passwordConfirmation'

type UserValidationFields = {
  [key in ValidationFields]?: string
}

type Validated = {
  [key in ValidationFields]?: boolean
}

const REASONS: UserValidationFields = {
  email: 'El correo ingresado ya se está utilizando en otra cuenta',
  identification: 'La identificación ingresada corresponde a otra cuenta',
  password: 'La contraseña debe tener una fuerza de seguridad suficiente',
}

export const useCreateUser = (): Hook => {
  const [user, setUser] = React.useState<IUserRequest>({
    identificationTypeId: '04',
  } as IUserRequest)
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState<ValidationFields[]>([])
  const [loading, setLoading] = React.useState<ValidationFields[]>([])
  const [reasons, setReasons] = React.useState<UserValidationFields>({} as UserValidationFields)
  const [lastFields, setLastFields] = React.useState({} as UserValidationFields)
  const [validated, setValidated] = React.useState<Validated>({} as Validated)
  const [creating, setCreating] = React.useState(false)

  const { showMessage } = useFeedback()

  const initialStateUser = {
    email: '',
    identification: '',
    identificationTypeId: '04',
    password: '',
    passwordConfirmation: '',
  }

  const handleChange = (key: keyof IUserRequest, value: any) => {
    setUser((current) => ({
      ...current,
      [key]: value,
    }))

    if (key === 'email' || key === 'identification') {
      setValidated((current) => ({
        ...current,
        [key]: validated[key] ? false : validated[key],
      }))
    }
    if (key === 'identificationTypeId') {
      setValidated((current) => ({
        ...current,
        identification: false,
      }))
    }

    if (key === 'password' || key === 'passwordConfirmation') {
      setValidated((current) => ({
        ...current,
        [key]: validated[key] ? false : validated[key],
      }))
    }
  }

  const createUser = React.useCallback(
    async (fromModal?: boolean, handleChangeVerification?: () => void) => {
      if (!validated.email || !validated.identification) {
        showMessage('Los campos de identificación, correo electrónico deben ser únicos', 'error', {
          horizontal: 'right',
          vertical: 'top',
        })
        return
      }
      if (!isEmail(user.email)) {
        showMessage('Ingresa un email válido', 'error')
        return
      }
      if (REQUIRE_VALIDATION_TYPES.includes(user.identificationTypeId)) {
        if (
          user.identificationTypeId === RUC_ID &&
          !validateSingleRegistryOfTaxpayers(user.identification)
        ) {
          showMessage('Ingresa un documento válido', 'error')
          return
        }
        if (!isEmail(user.email)) {
          showMessage('Ingresa un email válido', 'error')
          return
        }
        if (
          user.identificationTypeId === IDENTIFICATION_CARD_ID &&
          !validateIdentificationCard(user.identification)
        ) {
          showMessage('Ingresa un documento válido', 'error')
          return
        }
        if (user.identificationTypeId === PASSPORT && !validatePassport(user.identification)) {
          showMessage('Ingresa un documento válido', 'error')
          return
        }
        if (user.identificationTypeId === OUTSIDE_ID && !validateOutSideId(user.identification)) {
          showMessage('Ingresa un documento válido', 'error')
          return
        }
      }
      try {
        setCreating(true)
        await createUserService(user)
        await createCompanyService({ companyId: user.identification, companyName: user.email })

        ReactPixel.track('singUp')
        showMessage('Cuenta creada!', 'success', { horizontal: 'right', vertical: 'top' })
        const emailEncrypt = encryptParam(user.email)
        const path =
          process.env.REACT_APP_ROOT_PATH === ''
            ? `../marketplace/${ROUTES.feedback}/${ROUTES.verificationSent}?email=${emailEncrypt}`
            : `../${ROUTES.feedback}/${ROUTES.verificationSent}?email=${emailEncrypt}`
        if (fromModal !== undefined && handleChangeVerification !== undefined && fromModal) {
          handleChangeVerification()
        } else {
          navigate('../' + path)
          //window.open(path, '_blank')
        }

        return user
      } catch (error: any) {
        showMessage('Error en registro...', 'error', { horizontal: 'right', vertical: 'top' })
      } finally {
        setCreating(false)
        setValidated({} as Validated)
        setUser(initialStateUser)
      }
    },
    [validated, user]
  )

  const validateField = React.useCallback(
    async (key: ValidationFields) => {
      try {
        setLoading((current) => [...current, key])
        if (user[key] !== undefined && user[key] !== '') {
          if (key === 'identification') {
            if (REQUIRE_VALIDATION_TYPES.includes(user.identificationTypeId)) {
              if (
                user.identificationTypeId === RUC_ID &&
                !validateSingleRegistryOfTaxpayers(user.identification)
              ) {
                showMessage('Ingresa un documento válido', 'error')
                return
              }
              if (
                user.identificationTypeId === IDENTIFICATION_CARD_ID &&
                !validateIdentificationCard(user.identification)
              ) {
                showMessage('Ingresa un documento válido', 'error')
                return
              }
              if (
                user.identificationTypeId === PASSPORT &&
                !validatePassport(user.identification)
              ) {
                showMessage('Ingresa un documento válido', 'error')
                return
              }
              if (
                user.identificationTypeId === OUTSIDE_ID &&
                !validateOutSideId(user.identification)
              ) {
                showMessage('Ingresa un documento válido', 'error')
                return
              }
            }
            await validateExistingIdentificationService(user.identification)
            await verifyIfCompanyExists(user.identification)
          }
          if (key === 'email') {
            if (!isEmail(user.email)) {
              showMessage('Ingresa un email válido', 'error')
              return
            }
            await verifyIfCompanyNameExists(user.email) //Valida en administracion
            await validateExistingEmailService(user.email) //Valida en Security
          }

          if (key === 'password') {
            const result = passwordStrength(user.password ?? '')
            const { contains } = result

            if (contains.length < 4) {
              showMessage('La contraseña no cumple con lo solicitado', 'error')
              throw new Error('Password invalido')
            }
          }

          if (key === 'passwordConfirmation') {
            if (user.password !== user.passwordConfirmation) {
              showMessage('Las contraseñas no coinciden', 'error')
              throw new Error('Password invalido')
            }
          }

          setErrors((current) => current.filter((c) => c !== key))
          setReasons((current) => ({
            ...current,
            [key]: undefined,
          }))

          setValidated((current) => {
            return {
              ...current,
              [key]: true,
            }
          })
        }
      } catch (error) {
        setErrors((current) => {
          return [...current, key]
        })
        setReasons((current) => {
          return {
            ...current,
            [key]: REASONS[key],
          }
        })
        setValidated((current) => {
          return {
            ...current,
            [key]: false,
          }
        })
      } finally {
        setLastFields((current) => ({
          ...current,
          [key]: user[key],
        }))
        setLoading((current) => current.filter((c) => c !== key))
      }
    },
    [lastFields, user, showMessage]
  )

  return {
    errors,
    loading,
    user,
    reasons,
    validated,
    creating,
    handleChange,
    validateField,
    createUser,
  }
}
