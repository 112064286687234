import { useAuth, useNavigate } from 'hooks'
import { ROUTES, createProcessesService } from 'lib'

interface Hook {
  handleContinueShop: () => void
}

export const useFuntionShop = (): Hook => {
  const { user, isAuthenticated } = useAuth()
  const { animatedNavigation } = useNavigate()

  const handleCreateProcess = async () => {
    const process = {
      information: '{}',
      companyId: user.identification,
      securityUserId: user.userId,
    }
    const response = await createProcessesService(process)
    return response
  }

  const handleShop = async () => {
    try {
      const route = `${ROUTES.processes}/${'nuna'}`
      animatedNavigation(route)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShopNotLogged = async () => {
    try {
      const processNoLoggedRoute = `${ROUTES.processes}/nuna`
      animatedNavigation(processNoLoggedRoute)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleContinueShop = () => {
    isAuthenticated ? handleShop() : handleShopNotLogged()
  }

  return {
    handleContinueShop,
  }
}
